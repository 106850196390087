import React, { FC } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const SecondPage: FC = () => (
  <Layout>
    <SEO title="Önnur binding/losun" />
    <h3>Önnur binding/losun</h3>
    <p>
      Hér verður farið yfir aðra þætti en (jarðveg og trjágróður) sem geta valdið 
      bindingu eða losun í nýskógrækt:
    </p>
    <ul>
      <li>
        Losun frá jarðvegi vegna jarðvinnslu. Jarðvinnsla getur haft í för með
        sér losun frá jarðvegi og sópi (dauðum gróðri). Engar beinar mælingar
        eða rannsóknir hafa verið gerðar hér á landi en sú aðferð sem er notuð
        við að meta jarðvegsbindingu inniheldur losun vegna jarðvinnslu þ.e. að
        borin eru saman kolefnisforði svæða utan skógar í hefðbundinni nýtingu
        og svæða í skógrækt þar sem jarðvinnslu hefur verið beitt. Kolefnisforði
        skógræktarsvæða hefur reynst geyma meira kolefni en sambærileg skóglaus
        svæði og kolefnisforðinn eykst með auknu aldri skógarins.
      </li>
      <li>
        Binding í sópi. Sóp er skilgreint sem smágert dautt lífrænt efni sem
        liggur í skógarbotni. Rannsóknir hér á landi sýna að við nýskógrækt á
        sér stað binding í sópi. Hún hefur verið metin að jafnaði 0,517 tonn CO
        <sub>2</sub>á ha á ári frá gróðursetningu.
      </li>
      <li>
        Losun frá dauðum við. Þegar skógurinn vex og er ekki grisjaður verða
        einhver tré undir í baráttunni um ljósið og deyja. Kolefnið sem var í
        lífmassa lifandi trjáa er þá í dauðum við. Þá flyst kolefni úr lífmassa
        lifandi trjáa yfir í dauðan við. Þetta gerist líka þegar skógar eru
        grisjaðir. Dauður viður getur bæði verið ofanjarðarhluti standandi
        dauðra trjáa eða trjábolir sem liggja í skógarbotni. Dauður viður getur
        líka verið neðanjarðar í stubbum og grófrótum dauðra og felldra trjáa.
        Kolefnisforðinn sem er í dauðum við eykst vanalega með aldri skóga
        eftir því sem fleiri dauð tré og rótarhnyðjur bætast í forðann. Forðinn
        rýrnar þó líka vegna niðurbrots. Það er okkar reynsla frá mælingum í
        ræktuðum skógum á Íslandi að nýskógar sem eru lítið eða ekki grisjaðir
        eru vanalega með ekkert eða mjög lítið af dauðum við.
      </li>
      <li>
        Losun vegna áburðargjafar. Við áburðargjöf með köfnunarefnisáburði
        (köfnunarefni = N) verður uppgufun á N í formi N<sub>2</sub>O.
        Alþjóðlegur losunarstuðull er 0,01 kg N<sub>2</sub>O-N á hvert kg N í
        tilbúnum áburði. Eitt tonn af N í áburði er því að losa 10 kg af N í
        formi N<sub>2</sub>O. Það samsvarar 4,68 tonna CO<sub>2</sub> losun.
      </li>
      <li>
        Binding/losun í öðrum gróðri en trjám. Íslenskar rannsóknir hafa sýnt
        töluverðar sveiflur í kolefnisforða í öðrum gróðri en trjám við
        nýskógrækt en til lengri tíma (50 ára) hefur hvorki orðið aukning eða
        minnkun í kolefnisforða annars gróðurs en trjáa í skógum. Því er hvorki
        gert ráð fyrir bindingu eða losum frá öðrum gróðri en trjám.
      </li>
    </ul>
  </Layout>
)

export default SecondPage
